import React from 'react';
import PropTypes from 'prop-types';

import styles from './HourSliderQuestion.scss';
import ScaleBox from './components/ScaleBox/ScaleBox';

const selectButton = (value, onChange) => {
    onChange(value);
};

const HourSliderQuestion = ({
    input: { value, onChange },
    profilePic,
    questionSet,
    defaultValue
}) => {
    const currentValue = !value ? defaultValue : value;
    return (
        <div className={styles.container}>
            <div className={styles.box}>
                <div className={styles.scaleBarContainer}>
                    {
                        questionSet.map((data, index) => (
                            <div onClick={() => selectButton(data.number, onChange)} className={styles.scaleBar} key={index}>
                                <ScaleBox colour={data.colour} number={data.number} point={data.position}/>
                            </div>
                        ))
                    }
                </div>
                <div className={styles.labelContainer}>
                    <div className={styles.labelBox}>
                        <div>12+ hours</div>
                        <div>11 hours</div>
                        <div>10 hours</div>
                        <div>9 hours</div>
                        <div>8 hours</div>
                        <div>7 hours</div>
                        <div>6 hours</div>
                        <div>5 hours</div>
                        <div>4 or less hours</div>
                    </div>
                </div>
                <div className={styles.sliderContainer}>
                    <div className={styles.slider}>
                        <input
                            className={styles.range}
                            type={'range'}
                            min='1'
                            max='37'
                            value={currentValue}
                            onChange={(e) => selectButton(e.target.value, onChange)}
                        />
                    </div>
                    <div className={styles.pointerContainer} data-value={currentValue}>
                        <div className={styles.label}>
                            <div className={styles.sliderLine} />
                            <div className={styles.circleContainer}>
                                <div className={styles.pointerCircle} />
                                <div className={styles.triangle} />
                                <div className={styles.circle}>
                                    <div className={styles.profileWrapper}>
                                        <img src={profilePic} className={styles.profile} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

HourSliderQuestion.propTypes = {
    input: PropTypes.object,
    profilePic: PropTypes.string,
    defaultValue: PropTypes.number,
    questionSet: PropTypes.array,
};

export default HourSliderQuestion;
