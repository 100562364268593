import {
    RECEIVE_SCHEDULE_LIST
} from './consts';
import moment from 'moment';
import drProfile from 'assets/images/drProfile.png';

const calenderDate = () => {
    return {
        profilePic: drProfile,
        drName: 'Dr. M Williams',
        desc: 'My specialist areas of interest and expertise include weight and eating-related conditions, depression, and post-traumatic stress disorder.',
        scheduleList: [{
            date: moment().day(8).format('DD'),
            month: moment().day(8).format('MMMM'),
            day: moment().day(8).format('dddd'),
            fullDate: moment().day(8).format('YYYY-MM-DD'),
            calenderList: [
                {
                    startTime: '9:00 am',
                    endTime: '9:50 am'
                },
                {
                    startTime: '10:00 am',
                    endTime: '10:50 am'
                },
                {
                    startTime: '11:00 am',
                    endTime: '11:50 am'
                },
                {
                    startTime: '12:00 pm',
                    endTime: '12:50 pm'
                },
                {
                    startTime: '1:00 pm',
                    endTime: '1:50 pm'
                },
                {
                    startTime: '3:00 pm',
                    endTime: '3:50 pm'
                }
            ]
        },
        {
            date: moment().day(9).format('DD'),
            month: moment().day(9).format('MMMM'),
            day: moment().day(9).format('dddd'),
            fullDate: moment().day(9).format('YYYY-MM-DD'),
            calenderList: [
                {
                    startTime: '9:00 am',
                    endTime: '9:50 am'
                },
                {
                    startTime: '10:00 am',
                    endTime: '10:50 am'
                },
                {
                    startTime: '11:00 am',
                    endTime: '11:50 am'
                }
            ]
        },
        {
            date: moment().day(10).format('DD'),
            month: moment().day(10).format('MMMM'),
            day: moment().day(10).format('dddd'),
            fullDate: moment().day(10).format('YYYY-MM-DD'),
            calenderList: []
        },
        {
            date: moment().day(11).format('DD'),
            month: moment().day(11).format('MMMM'),
            day: moment().day(11).format('dddd'),
            fullDate: moment().day(11).format('YYYY-MM-DD'),
            calenderList: [
                {
                    startTime: '9:00 am',
                    endTime: '9:50 am'
                },
                {
                    startTime: '10:00 am',
                    endTime: '10:50 am'
                },
                {
                    startTime: '11:00 am',
                    endTime: '11:50 am'
                },
                {
                    startTime: '12:00 pm',
                    endTime: '12:50 pm'
                },
                {
                    startTime: '1:00 pm',
                    endTime: '1:50 pm'
                },
                {
                    startTime: '3:00 pm',
                    endTime: '3:50 pm'
                }
            ]
        },
        {
            date: moment().day(12).format('DD'),
            month: moment().day(12).format('MMMM'),
            day: moment().day(12).format('dddd'),
            fullDate: moment().day(12).format('YYYY-MM-DD'),
            calenderList: [
                {
                    startTime: '9:00 am',
                    endTime: '9:50 am'
                },
                {
                    startTime: '3:00 pm',
                    endTime: '3:50 pm'
                }
            ]
        }
        ]
    };
};

export const getScheduleList = () => (dispatch) => {
    dispatch({
        type: RECEIVE_SCHEDULE_LIST,
        data: calenderDate()
    });
};
