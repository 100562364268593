import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

import styles from './Map.scss';

const mapStateToProps = (state) => ({
    googleMapAPI: state.config.googleMapAPI,
});

const Map = ({googleMapAPI, address}) => {
    return (
        <div className={styles.container}>
            <div className={styles.location}>
                <FontAwesomeIcon icon={faMapMarkerAlt} className={styles.icon} />
                <div className={styles.text}>{address}</div>
            </div>
            <iframe
                className={styles.mapWrapper}
                width='100%'
                height='260'
                frameBorder='0'
                aria-hidden='false' tabIndex='0'
                src={`https://www.google.com/maps/embed/v1/place?key=${googleMapAPI}&q=${address}`}
                allowFullScreen />
        </div>
    );
};

Map.propTypes = {
    address: PropTypes.string.isRequired
};

export default connect(mapStateToProps)(Map);
