import {
    REQUEST_QUESTION,
    RECEIVE_QUESTION,
    RECEIVE_QUESTION_FAIL,
} from './consts';
import profilePic from 'assets/images/fakeProfile.png';
import getQuestionDataFromPath from './questionData';

export const getQuestionSet = (pathName) => (dispatch) => {
    dispatch({
        type: REQUEST_QUESTION,
    });

    const questionData = getQuestionDataFromPath(pathName);
    if (questionData) {
        dispatch({
            type: RECEIVE_QUESTION,
            data: {
                profilePic: profilePic, //temp will move to binding
                questionData,
                id: 'mark0.demo',
                description: 'Demo Survey'
            }
        });
    } else {
        dispatch({
            type: RECEIVE_QUESTION_FAIL,
            data: `Unable to fetch questions for path: ${pathName}`
        });
    }
};
