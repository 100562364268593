import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import drProfile from 'assets/images/drProfile.png';
import ptProfile from 'assets/images/fakeProfile.png';

import styles from './SubmitHeader.scss';

const SubmitHeader = ({data, onChange}) => {
    const dateWithDay = moment(data.date).format('dddd MMMM Do');

    return (
        <div className={styles.container}>
            <div className={styles.detail}>
                <div>
                    <img src={ptProfile} className={styles.profileImg} alt={'ptProfileImg'}/>
                </div>
                <div className={styles.lineWrapper}>
                    <div className={styles.line} />
                </div>
                <div>
                    <img src={drProfile} className={styles.profileImg} alt={'profileImg'}/>
                </div>
            </div>
            <div className={styles.appDetails}>
                <div className={styles.date}>{dateWithDay}</div>
                <div className={styles.timeWrapper}>
                    <div className={styles.timeBox}>
                        <div className={styles.timeText}>Start</div>
                        <div className={styles.time}>{data.startTime}</div>
                    </div>
                    <div className={styles.timeBox}>
                        <div className={styles.timeText}>End</div>
                        <div className={styles.time}>{data.endTime}</div>
                    </div>
                </div>
                <div className={styles.appointmentWrapper}>
                    <div className={styles.checkIconWrapper}>
                        <FontAwesomeIcon icon={faCheck} />
                    </div>
                    <div className={styles.text}>Appointment confirmed</div>
                </div>
                <div className={styles.appointmentDesc}>
                    You are booked in, Aida will send you a reminder the day before
                </div>
            </div>
        </div>
    );
};

SubmitHeader.propTypes = {
    data: PropTypes.any,
    onChange: PropTypes.func
};

export default SubmitHeader;
