import React from 'react';
import PropTypes from 'prop-types';

import styles from './SelectionButton.scss';

const selectButton = (value, onChange) => {
    onChange(value);
};

const SelectionButton = ({ onChange, modeList }) => {
    return (
        <div className={styles.selectionWrapper}>
            {
                modeList.map((data, index) => (
                    <div key={index}>
                        <label>
                            <input
                                value={data.key}
                                checked={data.isSelected}
                                type={'radio'}
                                name={'mode'}
                                onChange={(e) => selectButton(e.target.value, onChange)}
                            />
                            <div className={styles.selectBtn}>{data.text}</div>
                        </label>
                    </div>
                ))
            }
        </div>
    );
};

SelectionButton.propTypes = {
    modeList: PropTypes.array.isRequired,
    onChange: PropTypes.func
};

export default SelectionButton;
