import {
    HOME_PAGE,
    SURVEY_OVERALL,
    SURVEY_FEELING,
    SURVEY_GAD7,
    SURVEY_ENERGY,
    SURVEY_APPETITE,
    SURVEY_SLEEP,
    SURVEY_POSITIVE,
    SURVEY_NEGATIVE,
    SURVEY_GENERIC,
    THANK_YOU_PAGE,
    APPOINTMENT,
    APPOINTMENT_SUMMARY,
    APPOINTMENT_SUBMIT
} from './routeNames';
import { getQuestionSet } from '../QuestionForm/actions';
import { getScheduleList } from '../Notification/scheduleList/actions';

const getQuestion = (pathName) => (dispatch, getState) => getQuestionSet(pathName)(dispatch, getState);

const getScheduleSchedule = () => (dispatch, getState) => {
    return (
        Promise.all([
            getScheduleList()(dispatch, getState)
        ])
    );
};

export default function getRoutesMap () {
    return {
        [HOME_PAGE]: {
            path: HOME_PAGE
        },
        [SURVEY_OVERALL]: {
            path: SURVEY_OVERALL,
            thunk: getQuestion(),
        },
        [SURVEY_ENERGY]: {
            path: SURVEY_ENERGY,
            thunk: getQuestion(),
        },
        [SURVEY_APPETITE]: {
            path: SURVEY_APPETITE,
            thunk: getQuestion(),
        },
        [SURVEY_SLEEP]: {
            path: SURVEY_SLEEP,
            thunk: getQuestion(),
        },
        [SURVEY_FEELING]: {
            path: SURVEY_FEELING,
            thunk: getQuestion(),
        },
        [SURVEY_GAD7]: {
            path: SURVEY_GAD7,
            thunk: getQuestion(),
        },
        [SURVEY_POSITIVE]: {
            path: SURVEY_POSITIVE,
            thunk: getQuestion(),
        },
        [SURVEY_NEGATIVE]: {
            path: SURVEY_NEGATIVE,
            thunk: getQuestion(),
        },
        [SURVEY_GENERIC]: {
            path: SURVEY_GENERIC,
            thunk: getQuestion(),
        },
        [THANK_YOU_PAGE]: {
            path: THANK_YOU_PAGE,
        },
        [APPOINTMENT]: {
            path: APPOINTMENT,
            thunk: getScheduleSchedule()
        },
        [APPOINTMENT_SUMMARY]: {
            path :APPOINTMENT_SUMMARY,
            thunk: getScheduleSchedule()
        },
        [APPOINTMENT_SUBMIT]: {
            path :APPOINTMENT_SUBMIT,
            thunk: getScheduleSchedule()
        }
    };
}
