import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import thunk from 'redux-thunk';

import reducers from './reducers';
import getRoutesConfiguration from './routes/routesConfiguration';

const configureStore = (preloadedState, history) => {
    const {
        reducer: routesReducer,
        middleware: routesMiddleware,
        enhancer: routesEnhancer,
        thunk: routesThunk
    } = getRoutesConfiguration(history);

    const rootReducer = combineReducers({
        ...reducers,
        form: formReducer,
        location: routesReducer
    });

    const composeEnhancers =
        (process.env.REDUX_DEVTOOLS &&
            typeof window === 'object' &&
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
        compose;
    const enhancers = composeEnhancers(
        routesEnhancer,
        applyMiddleware(thunk, routesMiddleware)
    );
    return {
        store: createStore(rootReducer, preloadedState, enhancers),
        thunk: routesThunk
    };
};

export default configureStore;
