import React from 'react';
import PropTypes from 'prop-types';

import styles from './ContainerBlock.scss';

const ContainerBlock = ({ children }) => {
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                {children}
            </div>
        </div>
    );
};

ContainerBlock.propTypes = {
    children: PropTypes.any,
};

export default ContainerBlock;
