import React from 'react';
import PropTypes from 'prop-types';

import styles from './Layout.scss';

const Layout = ({ children }) => {
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                {children}
            </div>
        </div>
    );
};

Layout.propTypes = {
    children: PropTypes.any,
};

export default Layout;
