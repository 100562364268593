import bent from 'bent';
import { readCookie } from './cookies';

const SURVEY_SERVICE_URL =
    process.env.SURVEY_SERVICE_URL || 'survey-service.dev.tacklit.co';

const parseAnswers = (answers) =>
    Object.keys(answers).map((key) => answers[key]);

export default ({ name, answers }) => {
    const jwt = readCookie('jwt');

    if (jwt) {
        const submitSurvey = bent(
            `https://${SURVEY_SERVICE_URL}`,
            'json',
            'PUT',
            {
                Authorization: `Bearer ${jwt}`,
            }
        );

        return submitSurvey('/surveys', {
            respondent: { name },
            responses: parseAnswers(answers),
        });
    }
};
