import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import styles from './ToolTipCustom.scss';

const ToolTipCustom = ({name, children, className, place}) => {
    return (
        <Fragment>
            <ReactTooltip wrapper={'div'} id={name} place={place} effect={'solid'} globalEventOff='click'>
                <span>Coming soon</span>
            </ReactTooltip>
            <div className={styles.label} data-tip data-for={name} data-event='click focus'>
                {children}
            </div>
        </Fragment>
    );
};

ToolTipCustom.propTypes = {
    name: PropTypes.string.isRequired,
    children: PropTypes.any,
    className: PropTypes.string,
    place: PropTypes.string
};

export default ToolTipCustom;
