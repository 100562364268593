import React from 'react';
import PropTypes from 'prop-types';
import ToolTipCustom from '../../../../../components/ToolTipCustom/ToolTipCustom';

import styles from './AppointmentHeader.scss';

const AppointmentHeader = ({name, desc, profilePic}) => {
    return (
        <div className={styles.container}>
            <div className={styles.title}>Calendar</div>
            <div className={styles.detail}>
                <div>
                    <img src={profilePic} className={styles.profileImg} alt={'profileImg'}/>
                </div>
                <div className={styles.profileName}>{name}</div>
                <div className={styles.profileDesc}>{desc}</div>
            </div>
            <div className={styles.linkWrapper}>
                <ToolTipCustom place={'top'} name={'therapist'}>
                    <div className={styles.link}>Not my therapist</div>
                </ToolTipCustom>
            </div>
            <div className={styles.appDetails}>
                <div className={styles.question}>Are you new here?</div>
                <div className={styles.selectionWrapper}>
                    <div className={styles.selectBtnSelected}>Existing Client</div>
                    <ToolTipCustom place={'top'} name={'newClient'}>
                        <div className={styles.selectBtn}>New Client</div>
                    </ToolTipCustom>
                </div>
            </div>
        </div>
    );
};

AppointmentHeader.propTypes = {
    name: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
    profilePic: PropTypes.string
};

export default AppointmentHeader;
