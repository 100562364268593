import { REQUEST_SCHEDULE_LIST, RECEIVE_SCHEDULE_LIST } from './consts';

export default function scheduleListReducer (state = {}, action) {
    switch (action.type) {
    case RECEIVE_SCHEDULE_LIST:
        return {
            data: action.data,
            isFetched: true,
        };
    case REQUEST_SCHEDULE_LIST:
        return {
            ...state,
            isFetched: false,
        };
    default:
        return state;
    }
}
