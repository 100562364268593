import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    HOME_PAGE,
    SURVEY_ENERGY,
    SURVEY_APPETITE,
    SURVEY_SLEEP,
    SURVEY_OVERALL,
    SURVEY_FEELING,
    SURVEY_GAD7,
    SURVEY_POSITIVE,
    SURVEY_NEGATIVE,
    SURVEY_GENERIC,
    THANK_YOU_PAGE,
    APPOINTMENT,
    APPOINTMENT_SUMMARY,
    APPOINTMENT_SUBMIT
} from '../../../store/routes/routeNames';
import HomePage from '../HomePage/HomePage';
import ThankYouPage from '../ThankYouPage/ThankYouPage';
import QuestionPage from '../QuestionPage/QuestionPage';
import AppointCalender from '../AppointmentPage/AppointCalender/AppointCalender';
import SummaryPage from '../AppointmentPage/SummaryPage/SummaryPage';
import AppointmentSubmitPage from '../AppointmentPage/AppointmentSubmitPage/AppointmentSubmitPage';

const routeComponentsMap = {
    [HOME_PAGE]: <HomePage />,
    [THANK_YOU_PAGE]: <ThankYouPage />,
    [SURVEY_OVERALL]: <QuestionPage />,
    [SURVEY_ENERGY]: <QuestionPage />,
    [SURVEY_SLEEP]: <QuestionPage />,
    [SURVEY_APPETITE]: <QuestionPage />,
    [SURVEY_FEELING]: <QuestionPage />,
    [SURVEY_GAD7]: <QuestionPage />,
    [SURVEY_POSITIVE]: <QuestionPage />,
    [SURVEY_NEGATIVE]: <QuestionPage />,
    [SURVEY_GENERIC]: <QuestionPage />,
    [APPOINTMENT]: <AppointCalender />,
    [APPOINTMENT_SUMMARY]: <SummaryPage />,
    [APPOINTMENT_SUBMIT]: <AppointmentSubmitPage />
};

export const PageRouter = ({ routeName }) => routeComponentsMap[routeName];

PageRouter.propTypes = {
    routeName: PropTypes.string.isRequired,
};

export default connect(state => ({
    routeName: state.location.type,
}))(PageRouter);
