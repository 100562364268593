import React from 'react';
import PropTypes from 'prop-types';

import styles from './SelectionButton.scss';

const selectButton = (value, group, onChange) => {
    const massageValue = {
        questionId: group,
        answer: { key: value }
    };
    onChange(massageValue);
};

const SelectionButton = ({ answerList, group, onChange }) => {
    return (
        <div className={styles.answerBox}>
            {
                answerList.map((data, index) => (
                    <div key={index} className={styles.container}>
                        <label>
                            <input
                                value={data.key}
                                checked={data.isSelected}
                                type={'radio'}
                                name={group}
                                onChange={(e) => selectButton(e.target.value, group, onChange)}
                            />
                            <span>{data.text}</span>
                        </label>
                    </div>
                ))
            }
        </div>
    );
};

SelectionButton.propTypes = {
    answerList: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    group: PropTypes.string
};

export default SelectionButton;
