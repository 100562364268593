import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import drProfile from 'assets/images/drProfile.png';
import ptProfile from 'assets/images/fakeProfile.png';
import { push } from 'redux-first-router';
import { APPOINTMENT } from '../../../../../../store/routes/routeNames';

import styles from './SummaryHeader.scss';
import SelectionButton from '../SelectionButton/SelectionButton';

const backBtn = (trackingId, country) => {
    push(`${APPOINTMENT}?tracking_id=${trackingId}&country=${country}`);
};

const mode = [
    {
        key: 'inPerson',
        text: 'In Person',
        isSelected: true
    },
    {
        key: 'teleconference',
        text: 'Teleconference',
        isSelected: false
    }
];

const massageAppointmentMode = (appointmentModeSet, val) => {
    let newAppointmentMode = [];
    for (let data of appointmentModeSet) {
        newAppointmentMode.push({
            key: data.key,
            text: data.text,
            isSelected: val === data.key
        });
    }
    return newAppointmentMode;
};

const SummaryHeader = ({data, onChange, trackingId, country}) => {
    const [appointmentModeSet, setAppointmentModeSet] = useState(mode);
    const dateWithDay = moment(data.date).format('dddd MMMM Do');

    const changeMode = (modeVal) => {
        const selectedMode = massageAppointmentMode(mode, modeVal);
        setAppointmentModeSet(selectedMode);
        onChange(modeVal);
    };

    return (
        <div className={styles.container}>
            <div className={styles.titleContainer}>
                <div className={styles.iconWrapper} onClick={() => backBtn(trackingId, country)}>
                    <FontAwesomeIcon icon={faArrowLeft} className={styles.icon}/>
                </div>
                <div className={styles.title}>Appointment summary</div>
            </div>
            <div className={styles.detail}>
                <div>
                    <img src={ptProfile} className={styles.profileImg} alt={'ptProfileImg'}/>
                </div>
                <div className={styles.lineWrapper}>
                    <div className={styles.line} />
                </div>
                <div>
                    <img src={drProfile} className={styles.profileImg} alt={'profileImg'}/>
                </div>
            </div>
            <div className={styles.appDetails}>
                <div className={styles.date}>{dateWithDay}</div>
                <div className={styles.timeWrapper}>
                    <div className={styles.timeBox}>
                        <div className={styles.timeText}>Start</div>
                        <div className={styles.time}>{data.startTime}</div>
                    </div>
                    <div className={styles.timeBox}>
                        <div className={styles.timeText}>End</div>
                        <div className={styles.time}>{data.endTime}</div>
                    </div>
                </div>
                <SelectionButton modeList={appointmentModeSet} onChange={(e) => changeMode(e)}/>
            </div>
        </div>
    );
};

SummaryHeader.propTypes = {
    data: PropTypes.any,
    onChange: PropTypes.func,
    trackingId: PropTypes.string,
    country: PropTypes.string
};

export default SummaryHeader;
