import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { push } from 'redux-first-router';

import Button from '../../../../components/Button/Button';
import SliderQuestion from './components/SliderQuestion/SliderQuestion';

import styles from './EnergyLevelScaling.scss';
import {getCookies} from '../../../../../utils/cookies';

const EnergyLevelScaling = ({ data, profilePic, handleSubmit, nextPath, saveAnswer }) => {
    useEffect(() => {
        const getAnswer = getCookies('saveAnswer');
        setEnergyAnswer((getAnswer && getAnswer.scaleEnergyLevel) ? getAnswer.scaleEnergyLevel.answer.key : 6);
    });

    const [scalingList, setScaleList] = useState(data.questionSet);
    const [energyAnswer, setEnergyAnswer] = useState(6);

    const getPosition = (myValue, currentValue) => {
        const value = parseInt(currentValue, 10);
        if (myValue > value) {
            return 'up';
        } else if (myValue === value) {
            return 'middle';
        }
        return 'down';
    };

    const selectScaleBar = (value) => {
        let newScalingList = [];
        for (let data of scalingList) {
            newScalingList.push({
                number: data.number,
                colour: data.colour,
                position: getPosition(data.number, value)
            });
        }
        setScaleList(newScalingList);
    };

    const submitForm = ({ energyScalingLevel = energyAnswer }) => {
        saveAnswer({
            [data.id]: {
                questionId: data.id,
                answer: { key: Math.floor(energyScalingLevel) }
            }
        });
        push(nextPath);
    };

    return (
        <form className={styles.content} onSubmit={handleSubmit(submitForm)}>
            <div className={styles.questionContainer}>
                <Field
                    name='energyScalingLevel'
                    component={SliderQuestion}
                    defaultValue={energyAnswer}
                    profilePic={profilePic}
                    questionSet={scalingList}
                    onChange={selectScaleBar}
                />
            </div>
            <div className={styles.buttonContainer}>
                <div className={styles.buttonBox}>
                    <Button id='submit-energy-scaling-level-button' text={'Next'} />
                </div>
            </div>
        </form>
    );
};

EnergyLevelScaling.propTypes = {
    data: PropTypes.object.isRequired,
    profilePic: PropTypes.string.isRequired,
    nextPath: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func,
    saveAnswer: PropTypes.func.isRequired,
};

const ReduxForm = reduxForm({
    form: 'sliderQuestion',
})(EnergyLevelScaling);

export default connect()(ReduxForm);
