import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { push } from 'redux-first-router';

import Button from '../../../../components/Button/Button';

import styles from './VoteMood.scss';
import EmotionQuestion from './components/EmotionQuestion/EmotionQuestion';
import {getCookies, setCookies} from '../../../../../utils/cookies';

const formatSelections = (selections) => selections.reduce(
    (reduced, selection) => {
        return [
            ...reduced,
            {
                key: selection.key,
                voteCount: selection.voteCount,
                mood: selection.mood
            }
        ];
    }, []
);

const VoteMood = ({
    data,
    handleSubmit,
    nextPath,
    saveAnswer,
    chooseNextPath
}) => {
    const myValue = getCookies('saveEmojiAnswer') || data.questionSet;
    const getAnswer = getCookies('saveAnswer');
    const voteMoodAnswer = (getAnswer && getAnswer.voteMood) ? getAnswer.voteMood.answer : [];
    const [emojiList, setEmojiList] = useState(myValue);
    const [selectedEmojiList, setSelectedEmojiList] = useState(voteMoodAnswer);

    const emojiValue = (val) => {
        if (val > 1) {
            return 0;
        }
        return val+1;
    };

    const selectEmoji = (value) => {
        let newEmojiList = emojiList.map(list => {
            let returnValue = {...list};

            if (list.key === value) {
                returnValue.voteCount = emojiValue(returnValue.voteCount);
            }

            return returnValue;
        });

        setCookies('saveEmojiAnswer', newEmojiList, 1);
        setEmojiList(newEmojiList);

        const newSelectedList = newEmojiList.filter(x => x.key === value);

        for (let emoji of selectedEmojiList) {
            if (emoji.key !== value) {
                newSelectedList.push({
                    key: emoji.key,
                    text: emoji.text,
                    emoji: emoji.emoji,
                    mood: emoji.mood,
                    voteCount: emoji.key === value ? emojiValue(emoji.voteCount) : emoji.voteCount
                });
            }
        }
        setSelectedEmojiList(newSelectedList);
    };

    const submitForm = () => {
        saveAnswer({
            [data.id]: {
                questionId: data.id,
                answer: formatSelections(selectedEmojiList)
            }
        });
        chooseNextPath(selectedEmojiList);
        push(nextPath);
    };

    return (
        <form className={styles.content} onSubmit={handleSubmit(submitForm)}>
            <div className={styles.questionContainer}>
                <Field
                    name='emotionQuestion'
                    component={EmotionQuestion}
                    questionSet={emojiList}
                    onChange={selectEmoji}
                />
            </div>
            <div className={styles.buttonContainer}>
                <div className={styles.buttonBox}>
                    <Button id='submit-vote-mood-button' text={'Next'} />
                </div>
            </div>
        </form>
    );
};


VoteMood.propTypes = {
    handleSubmit: PropTypes.func,
    nextPath: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    saveAnswer: PropTypes.func.isRequired,
    chooseNextPath: PropTypes.func
};

const ReduxForm = reduxForm({
    form: 'moodVote',
})(VoteMood);

export default connect()(ReduxForm);
