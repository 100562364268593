import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { push } from 'redux-first-router';
import { APPOINTMENT_SUMMARY } from '../../../../../../store/routes/routeNames';
import { setCookies } from '../../../../../../utils/cookies';

import styles from './calender.scss';

const Calender = ({data, trackingId, country}) => {
    const chooseTime = (date, startTime, endTime) => {
        setCookies('appointmentDate', {
            date,
            startTime,
            endTime
        }, 1);
        push(`${APPOINTMENT_SUMMARY}?tracking_id=${trackingId}&country=${country}`);
    };

    return (
        <div className={styles.calenderContainer}>
            <div className={styles.dateWrapper}>
                <div className={styles.date}>
                    <span>{data.date}</span>
                    <span className={styles.dotWrapper}>
                        <span className={data.calenderList.length > 0 ? styles.availDot : styles.noAvailDot} />
                    </span>
                </div>
                <div className={styles.month}>{data.month}</div>
                <div className={styles.countDotContainer}>
                    <div className={styles.countDotWrapper}>
                        {
                            data.calenderList.map((date, index) => {
                                return (
                                    <div key={index} className={styles.appCountDot} />
                                );
                            })
                        }
                    </div>
                </div>
            </div>
            <div className={styles.calenderWrapper}>
                <div className={styles.day}>{data.day}</div>
                <div className={styles.desc}>{data.calenderList.length} Open appointments</div>
                <div className={styles.calenderBox}>
                    {
                        data.calenderList.map((time, index) => {
                            const startTime = moment(time.startTime, ['h:m a']).format('h:mm');
                            return (
                                <div key={index} className={styles.calender}
                                    onClick={() => chooseTime(data.fullDate, time.startTime, time.endTime)}
                                >
                                    {startTime} to {time.endTime}
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
};

Calender.propTypes = {
    data: PropTypes.object,
    trackingId: PropTypes.string,
    country: PropTypes.string
};

export default Calender;
