import { gad7AnswerHelper } from './questionUiData';

const mappingHelper = (data, helperData, key) => {
    return data.map (
        apiData => ({
            ...apiData,
            ...helperData.find(helperData => helperData[key] === apiData[key])
        })
    );
};

export const scalingMassage = (data, helperData) => {
    const mapHelper = mappingHelper(data, helperData, 'key');

    const massageScalingSet = [];
    for (let scalingData of mapHelper) {
        const keyToNumber = parseInt(scalingData.key, 10);
        massageScalingSet.push({
            number: keyToNumber,
            colour: scalingData.colour,
            description: scalingData.description,
            position: keyToNumber === 6 ? 'middle' : keyToNumber > 6 ? 'up' : 'down'
        });
    }
    return massageScalingSet;
};

export const sleepQualityMassage = (data, helperData) => {
    const mapHelper = mappingHelper(data, helperData, 'key');

    const massageScalingSet = [];
    for (let scalingData of mapHelper) {
        const keyToNumber = parseInt(scalingData.key, 10);
        massageScalingSet.push({
            number: keyToNumber,
            colour: scalingData.colour,
            description: scalingData.description,
            position: keyToNumber === 20,
            key: scalingData.hourKey,
        });
    }
    return massageScalingSet;
};

export const appetiteLevelMassage = (data, helperData) => {
    const mapHelper = mappingHelper(data, helperData, 'key');

    const massageScalingSet = [];
    for (let scalingData of mapHelper) {
        const keyToNumber = parseInt(scalingData.key, 10);
        massageScalingSet.push({
            number: keyToNumber,
            colour: scalingData.colour,
            description: scalingData.description,
            position: keyToNumber === 20
        });
    }
    return massageScalingSet;
};

export const emojiQuestionMassage = (data, helperData) => {
    const mapHelper = mappingHelper(data, helperData, 'key');

    const massageEmojiSet = [];
    for (let emojiData of mapHelper) {
        massageEmojiSet.push({
            key: emojiData.key,
            text: emojiData.text,
            emoji: emojiData.emoji,
            mood: emojiData.mood,
            voteCount: 0
        });
    }
    return massageEmojiSet;
};

const selectionAnswerMassage = (data) => {
    const mapHelper = mappingHelper(data, gad7AnswerHelper, 'key');
    const massageGad7AnswerSet = [];
    for (let gad7Data of mapHelper) {
        massageGad7AnswerSet.push({
            key: gad7Data.key,
            text: gad7Data.text
        });
    }
    return massageGad7AnswerSet;
};

export const selectionQuestionMassage = (data, helperData) => {
    const mapHelper = mappingHelper(data, helperData, 'id');

    const massageGad7Set = [];
    for (let gad7Data of mapHelper) {
        massageGad7Set.push({
            questionKey: gad7Data.id,
            question: gad7Data.text,
            answerType: selectionAnswerMassage(gad7Data.questionType.selections)
        });
    }
    return massageGad7Set;
};
