import React from 'react';

import styles from './Teleconference.scss';

const Teleconference = () => {
    return (
        <div className={styles.container}>
            <div className={styles.phone}>
                <div className={styles.title}>Phone:</div> +447515553516
            </div>
            <div>
                <div className={styles.title}>Video link:</div> http://www.zoom.com/drmwilliams/431jk321
            </div>
        </div>
    );
};

export default Teleconference;
