import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { push } from 'redux-first-router';

import Button from '../../../../components/Button/Button';
import SliderQuestion from './components/SliderQuestion/SliderQuestion';

import styles from './OverallScaling.scss';
import {getCookies} from '../../../../../utils/cookies';

const OverallScaling = ({ data, profilePic, nextPath, handleSubmit, saveAnswer }) => {
    useEffect(() => {
        const getAnswer = getCookies('saveAnswer');
        setOverallAnswer((getAnswer && getAnswer.scaleHappiness) ? getAnswer.scaleHappiness.answer.key : 6);
    });

    const [scalingList, setScaleList] = useState(data.questionSet);
    const [overallAnswer, setOverallAnswer] = useState(6);

    const getPosition = (myValue, currentValue) => {
        const value = parseInt(currentValue, 10);
        if (myValue > value) {
            return 'up';
        } else if (myValue === value) {
            return 'middle';
        }
        return 'down';
    };

    const selectScaleBar = (value) => {
        let newScalingList = [];
        for (let data of scalingList) {
            newScalingList.push({
                number: data.number,
                colour: data.colour,
                position: getPosition(data.number, value)
            });
        }
        setScaleList(newScalingList);
    };

    const submitForm = ({ sliderQuestion = overallAnswer }) => {
        saveAnswer({
            [data.id]: {
                questionId: data.id,
                answer: { key: Math.floor(sliderQuestion) }
            }
        });
        push(nextPath);
    };

    return (
        <form className={styles.content} onSubmit={handleSubmit(submitForm)}>
            <div className={styles.questionContainer}>
                <Field
                    name='sliderQuestion'
                    component={SliderQuestion}
                    defaultValue={overallAnswer}
                    profilePic={profilePic}
                    questionSet={scalingList}
                    onChange={selectScaleBar}
                />
            </div>
            <div className={styles.buttonContainer}>
                <div className={styles.buttonBox}>
                    <Button id='submit-overall-scaling-button' text={'Next'} />
                </div>
            </div>
        </form>
    );
};

OverallScaling.propTypes = {
    data: PropTypes.object.isRequired,
    profilePic: PropTypes.string.isRequired,
    nextPath: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func,
    saveAnswer: PropTypes.func.isRequired,
};

const ReduxForm = reduxForm({
    form: 'sliderQuestion',
})(OverallScaling);

export default connect()(ReduxForm);
