import React from 'react';
import { connect } from 'react-redux';

import Shell from 'Shell/Shell';
import { getCookies } from '../../../../utils/cookies';
import ContainerBlock from '../../../components/ContainerBlock/ContainerBlock';
import SubmitHeader from './components/SubmitHeader/SubmitHeader';
import Button from '../../../components/Button/Button';

import styles from './AppointmentSubmitPage.scss';

const AppointmentSubmitPage = () => {
    const getAppointmentDate = getCookies('appointmentDate');

    return (
        <Shell>
            <ContainerBlock>
                <div>
                    <SubmitHeader data={getAppointmentDate} />
                    <div className={styles.btnWrapper}>
                        <div className={styles.btnBox}>
                            <Button id='submit-schedule-button' text={'Add to my calendar'} />
                        </div>
                    </div>
                </div>
            </ContainerBlock>
        </Shell>
    );
};

export default connect()(AppointmentSubmitPage);
