import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import {getCookies, setCookies, } from '../../../utils/cookies';
import i18n from 'i18n';
import PropTypes from 'prop-types';
import {
    SURVEY_OVERALL,
    SURVEY_ENERGY,
    SURVEY_APPETITE,
    SURVEY_SLEEP,
    SURVEY_FEELING,
    SURVEY_GAD7,
    SURVEY_POSITIVE,
    SURVEY_NEGATIVE,
    SURVEY_GENERIC,
    THANK_YOU_PAGE
} from '../../../store/routes/routeNames';
import Shell from '../../components/Shell/Shell';
import Layout from '../../components/Layout/Layout';
import ContainerBlock from '../../components/ContainerBlock/ContainerBlock';
import Header from '../../components/Header/Header';
import OverallScaling from './components/OverallScaling/OverallScaling';
import VoteMood from './components/VoteMood/VoteMood';
import Gad7 from './components/Gad7/Gad7';
import DescribeFeeling from './components/DescribeFeeling/DescribeFeeling';
import DescribeFeelingNegative from './components/DescribeFeelingNegative/DescribeFeelingNegative';
import DescribeFeelingPositive from './components/DescribeFeelingPositive/DescribeFeelingPositive';
import EnergyLevelScaling from './components/EnergyLevelScaling/EnergyLevelScaling';
import SleepScaling from './components/SleepScaling/SleepScaling';
import AppetiteScaling from './components/AppetiteScaling/AppetiteScaling';
import submitAnswers from '../../../utils/submitAnswers';

const QuestionPage = ({
    questionDataSet,
    location
}) => {
    useEffect(() => {
        const firstName = getCookies('userName');
        setUsername(firstName);
    });

    const [userName, setUsername] = useState('');
    const [answers, setAnswers] = useState({});

    const [positiveQuestion, setPositiveQuestion] = useState(getCookies('positivePageRoute') || {});
    const [negativeQuestion, setNegativeQuestion] = useState(getCookies('negativePageRoute') || {});
    const [gad7Question, setGad7Question] = useState({});

    const addAnswer = (answer) => {
        setAnswers({
            ...answers,
            ...answer
        });

        setCookies('saveAnswer', {
            ...answers,
            ...answer
        }, 1);
    };

    const submit = (answer) => {
        setCookies('saveAnswer', {
            ...answers,
            ...answer
        }, 1);

        submitAnswers({
            name: userName,
            answers: {
                ...answers,
                ...answer
            }
        });
    };

    const chooseEmoji = (list, emotion) => {
        return list.find((list) => list.mood === emotion && list.voteCount > 1) || list.find((list) => list.mood === emotion && list.voteCount > 0);
    };

    const decideQuestion = (list) => {
        const getPositive = list.find((list) => list.mood === 'positive' && list.voteCount > 0);
        const getNegative = list.find((list) => list.mood === 'negative' && list.voteCount > 0);
        const positiveEmoji = chooseEmoji(list, 'positive');
        const negativeEmoji = chooseEmoji(list, 'negative');

        const gad7Route = {
            nextPath: getPositive || getNegative ? (getNegative ? SURVEY_NEGATIVE : SURVEY_POSITIVE) : SURVEY_GENERIC
        };
        setGad7Question(gad7Route);
        setCookies('gad7PageRoute', gad7Route, 1);

        if (getNegative && getPositive) {
            const negativeRoute = {
                nextPath: SURVEY_POSITIVE,
                submit: false,
                key: negativeEmoji.key,
                text: negativeEmoji.text
            };
            const positiveRoute = {
                nextPath: THANK_YOU_PAGE,
                submit: true,
                key: positiveEmoji.key,
                text: positiveEmoji.text
            };

            setNegativeQuestion(negativeRoute);
            setPositiveQuestion(positiveRoute);
            setCookies('negativePageRoute', negativeRoute, 1);
            setCookies('positivePageRoute', positiveRoute, 1);
        } else if (getNegative) {
            const negativeRoute = {
                nextPath: THANK_YOU_PAGE,
                submit: true,
                key: negativeEmoji.key,
                text: negativeEmoji.text
            };
            setNegativeQuestion(negativeRoute);
            setCookies('negativePageRoute', negativeRoute, 1);
        } else if (getPositive) {
            const positiveRoute = {
                nextPath: THANK_YOU_PAGE,
                submit: true,
                key: positiveEmoji.key,
                text: positiveEmoji.text
            };
            setPositiveQuestion(positiveRoute);
            setCookies('positivePageRoute', positiveRoute, 1);
        }
    };

    let questionData = questionDataSet.questionData[location.pathname];
    let components;
    switch (location.pathname) {
    case SURVEY_OVERALL:
        components =
                <OverallScaling
                    data={questionData}
                    profilePic={questionDataSet.profilePic}
                    nextPath={SURVEY_ENERGY}
                    saveAnswer={addAnswer}
                />;
        break;
    case SURVEY_ENERGY:
        components =
                <EnergyLevelScaling
                    data={questionData}
                    profilePic={questionDataSet.profilePic}
                    nextPath={SURVEY_SLEEP}
                    saveAnswer={addAnswer}
                />;
        break;
    case SURVEY_SLEEP:
        components =
                <SleepScaling
                    data={questionData}
                    profilePic={questionDataSet.profilePic}
                    nextPath={SURVEY_APPETITE}
                    saveAnswer={addAnswer}
                />;
        break;
    case SURVEY_APPETITE:
        components =
                <AppetiteScaling
                    data={questionData}
                    profilePic={questionDataSet.profilePic}
                    nextPath={SURVEY_FEELING}
                    saveAnswer={addAnswer}
                />;
        break;
    case SURVEY_FEELING:
        components =
                <VoteMood
                    data={questionData}
                    nextPath={SURVEY_GAD7}
                    saveAnswer={addAnswer}
                    chooseNextPath={decideQuestion}
                />;
        break;
    case SURVEY_GAD7:
        components =
                <Gad7
                    data={questionData}
                    nextPath={gad7Question.nextPath || SURVEY_GENERIC}
                    saveAnswer={addAnswer}
                />;
        break;
    case SURVEY_NEGATIVE:
        questionData = {
            ...questionData,
            title: i18n.t(
                'DescribeNegativeQuestion',
                { emotion: negativeQuestion.text ? negativeQuestion.text.toLowerCase() : 'flat' }
            ),
        };
        components =
                <DescribeFeelingNegative
                    data={questionData}
                    nextPath={negativeQuestion.nextPath}
                    saveAnswer={negativeQuestion.submit ? submit : addAnswer}
                    emotion={negativeQuestion.key || 'flat'}
                    questionId={'describeNegativeFeeling'}
                    btnText={negativeQuestion.submit ? 'Submit' : 'Next'}
                />;
        break;
    case SURVEY_POSITIVE:
        questionData = {
            ...questionData,
            title: i18n.t(
                'DescribePositiveQuestion',
                { emotion: positiveQuestion.text ? positiveQuestion.text.toLowerCase() : 'happy' }
            )
        };
        components =
                <DescribeFeelingPositive
                    data={questionData}
                    nextPath={positiveQuestion.nextPath}
                    saveAnswer={positiveQuestion.submit ? submit : addAnswer}
                    emotion={positiveQuestion.key || 'happy'}
                    questionId={'describePositiveFeeling'}
                    btnText={positiveQuestion.submit ? 'Submit' : 'Next'}
                />;
        break;
    case SURVEY_GENERIC:
        components =
                <DescribeFeeling
                    data={questionData}
                    nextPath={THANK_YOU_PAGE}
                    saveAnswer={submit}
                    questionId={'describeGenericFeeling'}
                    btnText={'Submit'}
                />;
        break;
    default:
        break;
    }

    return (
        <Shell>
            <ContainerBlock>
                <Header
                    name={userName}
                    data={questionData}
                />
                <Layout>
                    {components}
                </Layout>
            </ContainerBlock>
        </Shell>
    );
};

const mapStateToProps = state => {
    return {
        location: state.location,
        questionDataSet: state.questionSet
    };
};

QuestionPage.propTypes = {
    location: PropTypes.object.isRequired,
    questionDataSet: PropTypes.object
};

export default connect(mapStateToProps)(QuestionPage);
