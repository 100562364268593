import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import './Shell.scss';

const Shell = ({ children }) => {
    return (
        <Fragment>
            {children}
        </Fragment>
    );
};

Shell.propTypes = {
    children: PropTypes.any.isRequired
};

export default Shell;
