import React from 'react';
import PropTypes from 'prop-types';
import mock from 'assets/images/fakeProfile.png';
import QuestionHeader from './components/QuestionHeader/QuestionHeader';

import styles from './Header.scss';

const Header = ({ data, name }) => {
    const profileImg = false;
    return (
        <div className={styles.container}>
            <div className={styles.progressBarContainer}>
                <div className={styles.dotsContainer}>
                    <div className={data.id === 'scaleHappiness' ? styles.dotActive : styles.dot} />
                    <div className={data.id === 'scaleEnergyLevel' ? styles.dotActive : styles.dot} />
                    <div className={data.id === 'scaleSleepQuality' ? styles.dotActive : styles.dot} />
                    <div className={data.id === 'scaleAppetite' ? styles.dotActive : styles.dot} />
                    <div className={data.id === 'voteMood' ? styles.dotActive : styles.dot} />
                    <div className={data.id === 'gad7' ? styles.dotActive : styles.dot} />
                    <div className={data.id === 'describeNegativeFeeling' ? styles.dotActive : styles.dot} />
                    <div className={data.id === 'describePositiveFeeling' || data.id === 'describeGenericFeeling' ? styles.dotActive : styles.dot} />
                </div>
            </div>
            {
                data.id === 'scaleHappiness' ? (
                    <div className={styles.profileBox}>
                        <div className={styles.infoBox}>
                            <div className={!profileImg && styles.greetBoxWithoutProfile}>
                                <div className={styles.text}>Hello!</div>
                                <div className={styles.name}>{name} 👋</div>
                            </div>
                        </div>
                        {profileImg ? <div className={styles.imgBox}>
                            <img className={styles.img} src={mock}/>
                        </div> : null }
                    </div>
                ) : null
            }

            <QuestionHeader Question={data.title} Desc={data.desc} />
        </div>
    );
};

Header.propTypes = {
    data: PropTypes.object.isRequired,
    name: PropTypes.string
};

export default Header;
