import React, {useState} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { push } from 'redux-first-router';

import Button from '../../../../components/Button/Button';
import TextArea from '../../../../components/TextArea/TextArea';

import styles from './DescribeFeelingPositive.scss';
import {getCookies} from '../../../../../utils/cookies';

const DescribeFeelingPositive = ({handleSubmit, pristine, data, nextPath, saveAnswer, emotion, questionId, btnText }) => {
    const getAnswer = getCookies('saveAnswer');
    const myValue = (getAnswer && getAnswer.describePositiveFeeling) ? getAnswer.describePositiveFeeling.answer : null;
    const [isFillField, setFillField] = useState(false);

    const submitForm = ({ describePositiveFeeling = myValue }) => {
        const value = describePositiveFeeling || '';
        setFillField(!value);
        if (value) {
            if (value) {
                saveAnswer({
                    [data.id]: {
                        questionId: data.id,
                        answer: value,
                        emotion
                    }
                });
            }
            push(nextPath);
        }
    };

    const disabled = isFillField && pristine;

    return (
        <form className={styles.content} onSubmit={handleSubmit(submitForm)}>
            <div className={styles.questionContainer}>
                <Field
                    name={'describePositiveFeeling'}
                    component={TextArea}
                    myValue={myValue}
                    placeholder={'Text...'}
                />
                {
                    disabled && <div className={styles.error}>Please share something on this question before continuing</div>
                }
            </div>
            <div className={styles.buttonContainer}>
                <div className={styles.buttonBox}>
                    <Button id='submit-describe-feeling-positive-button' text={btnText} disabled={disabled}/>
                </div>
            </div>
        </form>
    );
};

DescribeFeelingPositive.propTypes = {
    data: PropTypes.object.isRequired,
    nextPath: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    saveAnswer: PropTypes.func.isRequired,
    emotion: PropTypes.string,
    questionId: PropTypes.string.isRequired,
    btnText: PropTypes.string.isRequired
};

const ReduxForm = reduxForm({
    form: 'describeFeeling',
})(DescribeFeelingPositive);

export default connect()(ReduxForm);
