import React from 'react';
import PropTypes from 'prop-types';

import styles from './ScaleBox.scss';

const ScaleBox = ({ number, point, colour }) => {
    switch (point) {
    case 'up':
        return (
            <div style={{zIndex: 1}} className={styles.container}>
                <div style={{borderBottomColor: colour}} className={styles.pointTop_Top} />
                <div style={{background: colour}} className={styles.scaleDown}>
                    <div className={styles.scaleTextTop}>{number}</div>
                </div>
                <div className={styles.pointTop_Down} />
            </div>
        );
    case 'middle':
        return (
            <div style={{zIndex: number}} className={styles.container}>
                <div style={{borderBottomColor: colour}} className={styles.pointTop_Top} />
                <div style={{background: colour}} className={styles.scaleDown}>
                    <div className={styles.scaleTextMiddle}>{number}</div>
                </div>
                <div style={{borderTopColor: colour}} className={styles.pointBottom_Down} />
            </div>
        );
    default:
        return (
            <div style={{zIndex: number}} className={styles.container}>
                <div className={styles.pointBottom_Top} />
                <div style={{background: colour}} className={styles.scaleDown}>
                    <div className={styles.scaleText}>{number}</div>
                </div>
                <div style={{borderTopColor: colour}} className={styles.pointBottom_Down} />
            </div>
        );

    }
};

ScaleBox.propTypes = {
    number: PropTypes.number,
    colour: PropTypes.string,
    point: PropTypes.string
};

export default ScaleBox;
