import {
    scalingMassage,
    emojiQuestionMassage,
    selectionQuestionMassage,
    sleepQualityMassage,
    appetiteLevelMassage
} from './messageHelper';
import {
    scaleHappinessHelper,
    scaleEnergyLevelHelper,
    scaleAppetiteHelper,
    scaleSleepQualityHelper,
    voteMoodHelper,
    gad7Helper,
    describeNegativeFeelingHelper,
    describePositiveFeelingHelper,
    describeGenericFeelingHelper
} from './questionUiData';
import questionData from './questionApiData';
import {
    SURVEY_OVERALL,
    SURVEY_FEELING,
    SURVEY_GAD7,
    SURVEY_ENERGY,
    SURVEY_APPETITE,
    SURVEY_SLEEP,
    SURVEY_POSITIVE,
    SURVEY_NEGATIVE,
    SURVEY_GENERIC
} from '../../routes/routeNames';

const formatQuestionData = (data) => ({
    id: data.id,
    description: data.description,
    questions: data.questions.reduce((accumulator, question) => {
        const enrichement = questionAnswersEnrichmentMap[question.id];
        return {
            ...accumulator,
            [question.id]: {
                id: question.id,
                required: question.required,
                title: enrichement.title,
                desc: enrichement.desc,
                questionSet: enrichement.getEnrichedQuestionSet(question)
            }
        };
    }, {})
});

const extractMinimumData = ({ title, desc }) => ({title, desc});

const questionAnswersEnrichmentMap = {
    'scaleHappiness': {
        ...extractMinimumData(scaleHappinessHelper),
        getEnrichedQuestionSet: ({ questionType: { selections }}) => scalingMassage(
            selections,
            scaleHappinessHelper.questionSet
        )
    },
    'scaleEnergyLevel': {
        ...extractMinimumData(scaleEnergyLevelHelper),
        getEnrichedQuestionSet: ({ questionType: { selections }}) => scalingMassage(
            selections,
            scaleEnergyLevelHelper.questionSet
        )
    },
    'scaleAppetite': {
        ...extractMinimumData(scaleAppetiteHelper),
        getEnrichedQuestionSet: ({ questionType: { selections }}) => appetiteLevelMassage(
            selections,
            scaleAppetiteHelper.questionSet
        )
    },
    'scaleSleepQuality': {
        ...extractMinimumData(scaleSleepQualityHelper),
        getEnrichedQuestionSet: ({ questionType: { selections }}) => sleepQualityMassage(
            selections,
            scaleSleepQualityHelper.questionSet
        )
    },
    'voteMood': {
        ...extractMinimumData(voteMoodHelper),
        getEnrichedQuestionSet: ({ questionType: { selections }}) => emojiQuestionMassage(
            selections,
            voteMoodHelper.questionSet
        )
    },
    'gad7': {
        ...extractMinimumData(gad7Helper),
        getEnrichedQuestionSet: ({ questionSet }) => selectionQuestionMassage(
            questionSet,
            gad7Helper.questionSet
        )
    },
    'describeNegativeFeeling': {
        ...extractMinimumData(describeNegativeFeelingHelper),
        getEnrichedQuestionSet: () => undefined
    },
    'describePositiveFeeling': {
        ...extractMinimumData(describePositiveFeelingHelper),
        getEnrichedQuestionSet: () => undefined
    },
    'describeGenericFeeling': {
        ...extractMinimumData(describeGenericFeelingHelper),
        getEnrichedQuestionSet: () => undefined
    }
};

const formattedQuestionData = formatQuestionData(questionData);

const routeQuestionsMap = {
    [SURVEY_OVERALL]: formattedQuestionData.questions.scaleHappiness,
    [SURVEY_ENERGY]: formattedQuestionData.questions.scaleEnergyLevel,
    [SURVEY_SLEEP]: formattedQuestionData.questions.scaleSleepQuality,
    [SURVEY_APPETITE]: formattedQuestionData.questions.scaleAppetite,
    [SURVEY_FEELING]: formattedQuestionData.questions.voteMood,
    [SURVEY_GAD7]: formattedQuestionData.questions.gad7,
    [SURVEY_POSITIVE]: formattedQuestionData.questions.describePositiveFeeling,
    [SURVEY_NEGATIVE]: formattedQuestionData.questions.describeNegativeFeeling,
    [SURVEY_GENERIC]: formattedQuestionData.questions.describeGenericFeeling,
};

export default (pathName) => pathName ? routeQuestionsMap[pathName] : routeQuestionsMap;
