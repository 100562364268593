export const getCookies = (name) => {
    try {
        let result = document.cookie.match(new RegExp(name + '=([^;]+)'));
        result && (result = JSON.parse(result[1]));
        return result;
    } catch (e) {
        console.log('get cookies not supported'); // eslint-disable-line no-console
        return '';
    }
};

export const readCookie = (name) => {
    try {
        const nameEQ = name + '=';
        const ca = document.cookie.split(';');
        for (let i=0;i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0)===' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    } catch (e) {
        console.log('read cookies not supported'); // eslint-disable-line no-console
        return '';
    }
};

export const setCookies = (name, value, expDate) => {
    try {
        const d = new Date();
        d.setTime(d.getTime() + (expDate * 24 * 60 * 60 * 1000));
        const expires = 'expires='+d.toUTCString();
        const domainPath = 'path=/';
        const cookie = name+'='+ JSON.stringify(value) + ';' + expires + ';' + domainPath + '; SameSite=Lax;';
        return document.cookie = cookie;
    } catch (e) {
        console.log('set cookies not supported'); // eslint-disable-line no-console
        return '';
    }
};

export function removeCookies (name) {
    try {
        const expires = 'expires=Thu, 31 Dec 2000 12:00:00 UTC';
        const cookie = name + '=;' + expires + ';' + 'path=/';
        return document.cookie = cookie;
    } catch (e) {
        console.log('remove cookies not supported'); // eslint-disable-line no-console
        return '';
    }
}
