import React from 'react';
import PropTypes from 'prop-types';

import SelectionButton from './components/SelectionButton/SelectionButton';

import styles from './SelectionQuestion.scss';

const SelectionQuestion = ({ questionSet, input: { onChange } }) => {
    return (
        <div className={styles.container}>
            {
                questionSet.map((data, index) => (
                    <div key={index} className={styles.question}>
                        <div className={styles.questionText}>{data.question}</div>
                        <SelectionButton answerList={data.answerType} group={data.questionKey} onChange={onChange}/>
                    </div>
                ))
            }
        </div>
    );
};

SelectionQuestion.propTypes = {
    input: PropTypes.object.isRequired,
    questionSet: PropTypes.array.isRequired,
};

export default SelectionQuestion;
