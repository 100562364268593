import { RECEIVE_CONFIG } from './const';

export default function configReducer (state = {}, action) {
    switch (action.type) {
    case RECEIVE_CONFIG:
        return {
            ...state,
            ...action.config
        };
    default:
        return state;
    }
}
