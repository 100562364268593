import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { setCookies } from '../../../utils/cookies';
import {
    SURVEY_OVERALL
} from '../../../store/routes/routeNames';
import { push } from 'redux-first-router';

import Shell from 'Shell/Shell';
import Button from '../../components/Button/Button';
import mock from 'assets/images/thankyou.png';
import InputField from './components/InputField/InputField';

import styles from './HomePage.scss';
import PropTypes from 'prop-types';

const HomePage = ({handleSubmit, pristine}) => {
    const [isFillField, setFillField] = useState(false);

    const submitForm = value => {
        const username = value ? value.firstName : '';
        setFillField(!username);

        if (username) {
            setCookies('userName', username, 1);
            push(SURVEY_OVERALL);
        }
    };

    const disabled = isFillField && pristine;

    return (
        <Shell>
            <div className={styles.container}>
                <div className={styles.wrapper}>
                    <form className={styles.content} onSubmit={handleSubmit(submitForm)}>
                        <div className={styles.title}>
                            Welcome
                        </div>
                        <img className={styles.img} src={mock} />
                        <div className={styles.fieldContainer}>
                            <div className={styles.question}>What is your first name?</div>
                            <Field
                                name='firstName'
                                component={InputField}
                                placeholder={'Enter name'}
                                maxValue={50}
                            />
                            {
                                disabled && <div className={styles.error}>Please insert your first name to continue</div>
                            }
                        </div>
                        <div className={styles.buttonContainer}>
                            <div className={styles.buttonBox}>
                                <Button id='submit-name-button' text={'Let\'s Begin!'} disabled={disabled} />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Shell>
    );
};

HomePage.propTypes = {
    handleSubmit: PropTypes.func,
    pristine: PropTypes.bool
};

const ReduxForm = reduxForm({
    form: 'getFirstName',
})(HomePage);

export default connect()(ReduxForm);
