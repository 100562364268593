import React, {useEffect} from 'react';
import { connect } from 'react-redux';

import Shell from 'Shell/Shell';
import mock from 'assets/images/thankyou.png';
import {removeCookies} from '../../../utils/cookies';

import styles from './ThankYouPage.scss';

const ThankYouPage = () => {
    useEffect(() => {
        removeCookies(`saveAnswer`);
        removeCookies(`gad7PageRoute`);
        removeCookies(`negativePageRoute`);
        removeCookies(`positivePageRoute`);
        removeCookies(`saveEmojiAnswer`);
        removeCookies(`saveGad7Answer`);
    });

    return (
        <Shell>
            <div className={styles.container}>
                <div className={styles.wrapper}>
                    <div className={styles.content}>
                        <div className={styles.title}>
                            Thank you for sharing 👋
                        </div>
                        <img className={styles.img} src={mock} />
                        <div className={styles.desc}>
                            See you next time
                        </div>
                    </div>
                </div>
            </div>
        </Shell>
    );
};

export default connect()(ThankYouPage);
