export const scaleHappinessHelper = {
    id: 'scaleHappiness',
    title: 'Overall, how are you feeling?',
    desc: 'Drag and drop your avatar',
    questionSet: [
        {
            key: '11',
            colour: '#2c8839'
        },
        {
            key: '10',
            colour: '#3db654'
        },
        {
            key: '9',
            colour: '#4ee458'
        },
        {
            key: '8',
            colour: '#bceb5f'
        },
        {
            key: '7',
            colour: '#e1f5bc'
        },
        {
            key: '6',
            colour: '#dadada'
        },
        {
            key: '5',
            colour: '#faf2bf'
        },
        {
            key: '4',
            colour: '#fde24b'
        },
        {
            key: '3',
            colour: '#fabd49'
        },
        {
            key: '2',
            colour: '#f88a37'
        },
        {
            key: '1',
            colour: '#f50e02'
        }
    ]
};

export const scaleEnergyLevelHelper = {
    id: 'scaleEnergyLevel',
    title: 'How are your energy levels?',
    desc: 'Drag and drop your avatar',
    questionSet: [
        {
            key: '11',
            colour: '#2c8839'
        },
        {
            key: '10',
            colour: '#3db654'
        },
        {
            key: '9',
            colour: '#4ee458'
        },
        {
            key: '8',
            colour: '#bceb5f'
        },
        {
            key: '7',
            colour: '#e1f5bc'
        },
        {
            key: '6',
            colour: '#dadada'
        },
        {
            key: '5',
            colour: '#faf2bf'
        },
        {
            key: '4',
            colour: '#fde24b'
        },
        {
            key: '3',
            colour: '#fabd49'
        },
        {
            key: '2',
            colour: '#f88a37'
        },
        {
            key: '1',
            colour: '#f50e02'
        }
    ]
};

export const scaleAppetiteHelper = {
    id: 'scaleAppetite',
    title: 'How would you describe your appetite and eating habits the last few days?',
    desc: 'Compared to what you’d consider your usual pattern',
    questionSet: [
        {
            key: '37',
            colour: '#f50e02'
        },
        {
            key: '36',
            colour: '#d52f45'
        },
        {
            key: '35',
            colour: '#e0713a'
        },
        {
            key: '34',
            colour: '#f78104'
        },
        {
            key: '33',
            colour: '#fabb31'
        },
        {
            key: '32',
            colour: '#fabb31'
        },
        {
            key: '31',
            colour: '#fee737'
        },
        {
            key: '30',
            colour: '#e0dca9'
        },
        {
            key: '29',
            colour: '#d7f6b9'
        },
        {
            key: '28',
            colour: '#d7f6b9'
        },
        {
            key: '27',
            colour: '#a5f259'
        },
        {
            key: '26',
            colour: '#9de856'
        },
        {
            key: '25',
            colour: '#4de254'
        },
        {
            key: '24',
            colour: '#51ec5a'
        },
        {
            key: '23',
            colour: '#4ee355'
        },
        {
            key: '22',
            colour: '#40bf56'
        },
        {
            key: '21',
            colour: '#40c056'
        },
        {
            key: '20',
            colour: '#2f903a'
        },
        {
            key: '19',
            colour: '#40bf56'
        },
        {
            key: '18',
            colour: '#40bf56'
        },
        {
            key: '17',
            colour: '#a1ec58'
        },
        {
            key: '16',
            colour: '#a6f35a'
        },
        {
            key: '15',
            colour: '#d9fabb'
        },
        {
            key: '14',
            colour: '#f1eeb8'
        },
        {
            key: '13',
            colour: '#f6f2bb'
        },
        {
            key: '12',
            colour: '#f9f5bd'
        },
        {
            key: '11',
            colour: '#fde738'
        },
        {
            key: '10',
            colour: '#fee737'
        },
        {
            key: '9',
            colour: '#fbbd34'
        },
        {
            key: '8',
            colour: '#fbbd34'
        },
        {
            key: '7',
            colour: '#fabb31'
        },
        {
            key: '6',
            colour: '#e0713a'
        },
        {
            key: '5',
            colour: '#e0713a'
        },
        {
            key: '4',
            colour: '#d52f45'
        },
        {
            key: '3',
            colour: '#d52f45'
        },
        {
            key: '2',
            colour: '#f50e02'
        },
        {
            key: '1',
            colour: '#f50e02'
        },
    ]
};

export const scaleSleepQualityHelper = {
    id: 'scaleSleepQuality',
    title: 'How have you slept the last few nights?',
    desc: 'Select the average hours per night',
    questionSet: [
        {
            key: '37',
            colour: '#f50e02'
        },
        {
            key: '36',
            colour: '#d52f45'
        },
        {
            key: '35',
            colour: '#e0713a'
        },
        {
            key: '34',
            colour: '#f78104'
        },
        {
            key: '33',
            colour: '#fabb31'
        },
        {
            key: '32',
            colour: '#fabb31'
        },
        {
            key: '31',
            colour: '#fee737'
        },
        {
            key: '30',
            colour: '#e0dca9'
        },
        {
            key: '29',
            colour: '#d7f6b9'
        },
        {
            key: '28',
            colour: '#d7f6b9'
        },
        {
            key: '27',
            colour: '#a5f259'
        },
        {
            key: '26',
            colour: '#9de856'
        },
        {
            key: '25',
            colour: '#4de254'
        },
        {
            key: '24',
            colour: '#51ec5a'
        },
        {
            key: '23',
            colour: '#4ee355'
        },
        {
            key: '22',
            colour: '#40bf56'
        },
        {
            key: '21',
            colour: '#40c056'
        },
        {
            key: '20',
            colour: '#2f903a'
        },
        {
            key: '19',
            colour: '#40bf56'
        },
        {
            key: '18',
            colour: '#40bf56'
        },
        {
            key: '17',
            colour: '#a1ec58'
        },
        {
            key: '16',
            colour: '#a6f35a'
        },
        {
            key: '15',
            colour: '#d9fabb'
        },
        {
            key: '14',
            colour: '#f1eeb8'
        },
        {
            key: '13',
            colour: '#f6f2bb'
        },
        {
            key: '12',
            colour: '#f9f5bd'
        },
        {
            key: '11',
            colour: '#fde738'
        },
        {
            key: '10',
            colour: '#fee737'
        },
        {
            key: '9',
            colour: '#fbbd34'
        },
        {
            key: '8',
            colour: '#fbbd34'
        },
        {
            key: '7',
            colour: '#fabb31'
        },
        {
            key: '6',
            colour: '#e0713a'
        },
        {
            key: '5',
            colour: '#e0713a'
        },
        {
            key: '4',
            colour: '#d52f45'
        },
        {
            key: '3',
            colour: '#d52f45'
        },
        {
            key: '2',
            colour: '#f50e02'
        },
        {
            key: '1',
            colour: '#f50e02'
        },
    ]
};

export const voteMoodHelper = {
    id: 'voteMood',
    title: 'Which emotions best fit how you’re feeling today?',
    desc: 'One tap for "a little", two taps for "a lot"',
    questionSet: [
        {
            key: 'happy',
            text: 'Happy',
            emoji: '😄'
        },
        {
            key: 'grateful',
            text: 'Grateful',
            emoji: '🤗'
        },
        {
            key: 'loved',
            text: 'Loved',
            emoji: '🥰'
        },
        {
            key: 'respected',
            text: 'Respected',
            emoji: '🙇'
        },
        {
            key: 'thoughtful',
            text: 'Thoughtful',
            emoji: '🤔'
        },
        {
            key: 'hopeful',
            text: 'Hopeful',
            emoji: '🤞'
        },
        {
            key: 'nervous',
            text: 'Nervous',
            emoji: '😧'
        },
        {
            key: 'anxious',
            text: 'Anxious',
            emoji: '😰'
        },
        {
            key: 'worried',
            text: 'Worried',
            emoji: '😟'
        },
        {
            key: 'angry',
            text: 'Angry',
            emoji: '😠'
        },
        {
            key: 'tired',
            text: 'Tired',
            emoji: '😴'
        },
        {
            key: 'frustrated',
            text: 'Frustrated',
            emoji: '😤'
        },
        {
            key: 'bored',
            text: 'Bored',
            emoji: '🥱'
        },
        {
            key: 'lonely',
            text: 'Lonely',
            emoji: '😶'
        },
        {
            key: 'mischievous',
            text: 'Mischievous',
            emoji: '😜'
        },
        {
            key: 'strong',
            text: 'Strong',
            emoji: '💪'
        },
        {
            key: 'flat',
            text: 'Flat',
            emoji: '😒'
        },
        {
            key: 'upset',
            text: 'Upset',
            emoji: '😢'
        },
        {
            key: 'unwell',
            text: 'Unwell',
            emoji: '🤒'
        }
    ]
};

export const gad7AnswerHelper = [
    {
        key: 'notAtAll',
        text: 'Not at all'
    },
    {
        key: 'severalDays',
        text: 'Several days'
    },
    {
        key: 'moreThanHalfTheDays',
        text: 'More than half the days'
    },
    {
        key: 'nearlyEveryDay',
        text: 'Nearly every day'
    }
];

export const gad7Helper = {
    id: 'gad7',
    title: 'When you reflect on the last couple of weeks have you been bothered by any of these?',
    desc: '',
    questionSet: [
        {
            id: 'gad7q1',
            text: 'Feeling nervous, anxious, or on edge'
        },
        {
            id: 'gad7q2',
            text: 'Not being able to stop or control worrying'
        },
        {
            id: 'gad7q3',
            text: 'Worrying too much about different things'
        },
        {
            id: 'gad7q4',
            text: 'Trouble relaxing'
        },
        {
            id: 'gad7q5',
            text: 'Being so restless that it\'s hard to sit still'
        },
        {
            id: 'gad7q6',
            text: 'Becoming easily annoyed or irritable'
        },
        {
            id: 'gad7q7',
            text: 'Feeling afraid as if something awful might happen'
        }
    ]
};

export const describeNegativeFeelingHelper = {
    id: 'describeNegativeFeeling',
    title: 'What is one thing you are you can identify that has made you feel flat?',
    desc: 'Share as much as you feel comfortable with',
};

export const describePositiveFeelingHelper = {
    id: 'describePositiveFeeling',
    title: 'Good you are feeling grateful today, anything behind it?',
    desc: 'Share as much as you feel comfortable with',
};

export const describeGenericFeelingHelper = {
    id: 'describeGenericFeeling',
    title: 'What’s the best thing that has happened to you in the last month?',
    desc: 'It’s important to reflect on the good times as well as the difficult times',
};
