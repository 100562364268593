import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styles from './TextArea.scss';


const TextArea = ({ placeholder , input: { onChange }, name, myValue }) => {
    const [textArea, setTextArea] = useState({
        rows: 1,
        minRows: 1,
        maxRows: 8,
    });

    const handleChange = (event) => {
        const getValue = event.target.value;
        const textAreaLineHeight = 24;

        const minRows = textArea.minRows;
        const maxRows = textArea.maxRows;

        const previousRows = event.target.rows;

        event.target.rows = minRows;

        const currentRows = Math.floor(event.target.scrollHeight / textAreaLineHeight);

        if (currentRows === previousRows) {
            event.target.rows = currentRows;
        }

        if (currentRows >= maxRows) {
            event.target.rows = maxRows;
            event.target.scrollTop = event.target.scrollHeight;
        }

        setTextArea({
            ...textArea,
            rows: currentRows < maxRows ? currentRows : maxRows,
        });

        onChange(getValue);
    };

    return (
        <div className={styles.container}>
            <textarea
                defaultValue={myValue}
                id={name}
                rows={textArea.rows}
                className={styles.textArea}
                placeholder={placeholder}
                onChange={(e) => handleChange(e)}
            />
        </div>
    );
};

TextArea.propTypes = {
    name: PropTypes.string,
    input: PropTypes.object.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string
};

export default TextArea;
