import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { push } from 'redux-first-router';

import Button from '../../../../components/Button/Button';
import SelectionQuestion from './components/SelectionQuestion/SelectionQuestion';

import styles from './Gad7.scss';
import {getCookies, setCookies} from '../../../../../utils/cookies';

const massageAnswer = (answerSet, questionKey, selectedList) => {
    let newGad7Answer = [];
    for (let data of answerSet) {
        const getKeyFromAnswer = selectedList.find(data => data.questionId === questionKey);
        newGad7Answer.push({
            key: data.key,
            text: data.text,
            isSelected: getKeyFromAnswer ? getKeyFromAnswer.answer.key === data.key : false
        });
    }
    return newGad7Answer;
};

const massageQuestionSet = (questionSet, selectedList) => {
    let newGad7List = [];
    for (let data of questionSet) {
        newGad7List.push({
            questionKey: data.questionKey,
            question: data.question,
            answerType: massageAnswer(data.answerType, data.questionKey, selectedList)
        });
    }
    return newGad7List;
};

const Gad7 = ({ data, handleSubmit, nextPath, saveAnswer }) => {
    const getAnswer = getCookies('saveGad7Answer');
    const getAnswerFromCookies = getAnswer || [];
    const [selectedList, setSelectedList] = useState(getAnswerFromCookies);
    const questionSet = massageQuestionSet(data.questionSet, getAnswerFromCookies);
    const [gad7QuestionSet, setGad7QuestionSet] = useState(questionSet);
    const [isFillField, setFillField] = useState(true);

    const selectSelectionAnswer = (value) => {
        const selectedID = selectedList.filter(x => x.questionId === value.questionId);
        if (selectedID.length > 0) {
            const updatedItems = selectedList.map(el => el.questionId === value.questionId ? value : el);
            setSelectedList(updatedItems);
            const massageQuestion = massageQuestionSet(data.questionSet, updatedItems);
            setGad7QuestionSet(massageQuestion);
        } else {
            selectedList.push({
                questionId: value.questionId,
                answer: { key: value.answer.key }
            });
            setSelectedList(selectedList);
            const massageQuestion = massageQuestionSet(data.questionSet, selectedList);
            setGad7QuestionSet(massageQuestion);
        }

        if (selectedList.length >= 7) {
            setFillField(selectedList.length >= 7);
        }
    };

    const submitForm = () => {
        if (selectedList.length >= 7) {
            const answers = selectedList.reduce((accumulator, answer) => {
                return {
                    ...accumulator,
                    [answer.questionId]: {
                        ...answer
                    }
                };
            }, {});
            saveAnswer(answers);
            setCookies('saveGad7Answer', selectedList, 1);
            setFillField(true);
            push(nextPath);
        } else {
            setFillField(false);
        }
    };

    return (
        <form className={styles.content} onSubmit={handleSubmit(submitForm)}>
            <div className={styles.questionContainer}>
                <Field
                    name='gad7Question'
                    component={SelectionQuestion}
                    questionSet={gad7QuestionSet}
                    onChange={selectSelectionAnswer}
                />
            </div>
            {
                !isFillField && <div className={styles.error}>Oops looks like you've missed an answer here</div>
            }
            <div className={styles.buttonContainer}>
                <div className={styles.buttonBox}>
                    <Button id='submit-gad7-button' text={'Next'} disabled={!isFillField} />
                </div>
            </div>
        </form>
    );
};

Gad7.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    nextPath: PropTypes.string.isRequired,
    saveAnswer: PropTypes.func.isRequired,
};

const ReduxForm = reduxForm({
    form: 'gad7',
})(Gad7);

export default connect()(ReduxForm);
