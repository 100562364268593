export default {
    id: 'mark0.demo',
    description: 'Demo survey.',
    metadata: {
        'activateAt': '2020 Jun 26 16:25:05+0800',
        'deactivateAt': '2020 Jun 26 16:25:05+0800',
        'createdAt': '2020 Jun 26 16:25:05+0800',
    },
    questions: [
        {
            id: 'scaleHappiness',
            description: 'Scale your happiness.',
            required: true,
            questionType: {
                type: 'multipleChoice',
                maxSelection: 1,
                selections: [
                    { key: '11', description: 'damn happy' },
                    { key: '10', description: 'Very unhappy' },
                    { key: '9', description: 'Unhappy' },
                    { key: '8', description: 'Very unhappy' },
                    { key: '7', description: 'Very unhappy' },
                    { key: '6', description: 'Getting unhappy' },
                    { key: '5', description: 'Neutral but closer to happy' },
                    { key: '4', description: 'Getting happy' },
                    { key: '3', description: 'Sort of happy' },
                    { key: '2', description: 'Happy' },
                    { key: '1', description: 'Very happy' }
                ]
            }
        },
        {
            id: 'scaleSleepQuality',
            description: 'Scale your sleep time',
            required: true,
            questionType: {
                type: 'multipleChoice',
                maxSelection: 1,
                selections: [
                    { key: '37', hourKey: '12:30', description: '12+ hour' },
                    { key: '36', hourKey: '12:15', description: '12+ hour' },
                    { key: '35', hourKey: '12:00', description: '12' },
                    { key: '34', hourKey: '11:45', description: '11 hour' },
                    { key: '33', hourKey: '11:30', description: '11 hour' },
                    { key: '32', hourKey: '11:15', description: '11 hour' },
                    { key: '31', hourKey: '11:00', description: '11 hour' },
                    { key: '30', hourKey: '10:45', description: '10 hour' },
                    { key: '29', hourKey: '10:30', description: '10 hour' },
                    { key: '28', hourKey: '10:15', description: '10 hour' },
                    { key: '27', hourKey: '10:00', description: '10 hour' },
                    { key: '26', hourKey: '09:45', description: '10 hour' },
                    { key: '25', hourKey: '09:30', description: '10 hour' },
                    { key: '24', hourKey: '09:15', description: '10 hour' },
                    { key: '23', hourKey: '09:00', description: '10 hour' },
                    { key: '22', hourKey: '08:45', description: '10 hour' },
                    { key: '21', hourKey: '08:30', description: '10 hour' },
                    { key: '20', hourKey: '08:15', description: 'hour' },
                    { key: '19', hourKey: '08:00', description: 'hour' },
                    { key: '18', hourKey: '07:45', description: 'hour' },
                    { key: '17', hourKey: '07:30', description: 'hour' },
                    { key: '16', hourKey: '07:15', description: 'hour' },
                    { key: '15', hourKey: '07:00', description: 'hour' },
                    { key: '14', hourKey: '06:45', description: 'hour' },
                    { key: '13', hourKey: '06:30', description: 'hour' },
                    { key: '12', hourKey: '06:15', description: 'hour' },
                    { key: '11', hourKey: '06:00', description: 'hour' },
                    { key: '10', hourKey: '05:45', description: 'hour' },
                    { key: '9', hourKey: '05:30', description: 'hour' },
                    { key: '8', hourKey: '05:15', description: 'hour' },
                    { key: '7', hourKey: '05:00', description: 'hour' },
                    { key: '6', hourKey: '04:45', description: 'hour' },
                    { key: '5', hourKey: '04:30', description: 'hour' },
                    { key: '4', hourKey: '04:15', description: 'hour' },
                    { key: '3', hourKey: '04:00', description: 'hour'  },
                    { key: '2', hourKey: '03:45', description: 'hour' },
                    { key: '1', hourKey: '03:30', description: 'hour' }
                ]
            }
        },
        {
            id: 'scaleEnergyLevel',
            description: 'Scale your energy power',
            required: true,
            questionType: {
                type: 'multipleChoice',
                maxSelection: 1,
                selections: [
                    { key: '11', description: 'damn happy' },
                    { key: '10', description: 'Very unhappy' },
                    { key: '9', description: 'Unhappy' },
                    { key: '8', description: 'Very unhappy' },
                    { key: '7', description: 'Very unhappy' },
                    { key: '6', description: 'Getting unhappy' },
                    { key: '5', description: 'Neutral but closer to happy' },
                    { key: '4', description: 'Getting happy' },
                    { key: '3', description: 'Sort of happy' },
                    { key: '2', description: 'Happy' },
                    { key: '1', description: 'Very happy' }
                ]
            }
        },
        {
            id: 'scaleAppetite',
            description: 'Scale your appetite power',
            required: true,
            questionType: {
                type: 'multipleChoice',
                maxSelection: 1,
                selections: [
                    { key: '37', description: 'much higher' },
                    { key: '36', description: 'much higher' },
                    { key: '35', description: 'much higher' },
                    { key: '34', description: 'much higher' },
                    { key: '33', description: 'much higher' },
                    { key: '32', description: 'much higher' },
                    { key: '31', description: 'much higher' },
                    { key: '30', description: 'much higher' },
                    { key: '29', description: 'higher' },
                    { key: '28', description: 'higher' },
                    { key: '27', description: 'higher' },
                    { key: '26', description: 'higher' },
                    { key: '25', description: 'higher' },
                    { key: '24', description: 'higher' },
                    { key: '23', description: 'higher' },
                    { key: '22', description: 'normal' },
                    { key: '21', description: 'normal' },
                    { key: '20', description: 'normal' },
                    { key: '19', description: 'normal' },
                    { key: '18', description: 'normal' },
                    { key: '17', description: 'normal' },
                    { key: '16', description: 'normal' },
                    { key: '15', description: 'lower' },
                    { key: '14', description: 'lower' },
                    { key: '13', description: 'lower' },
                    { key: '12', description: 'lower' },
                    { key: '11', description: 'lower' },
                    { key: '10', description: 'lower' },
                    { key: '9', description: 'lower' },
                    { key: '8', description: 'much lower' },
                    { key: '7', description: 'much lower' },
                    { key: '6', description: 'much lower' },
                    { key: '5', description: 'much lower' },
                    { key: '4', description: 'much lower' },
                    { key: '3', description: 'much lower'  },
                    { key: '2', description: 'much lower' },
                    { key: '1', description: 'much lower' }
                ]
            }
        },
        {
            id: 'voteMood',
            description: 'Vote for the current mood.',
            required: true,
            questionType: {
                type: 'vote',
                maxAvailableVotes: 0,
                maxVotesPerSelection: 2,
                selections: [
                    {
                        key: 'happy',
                        mood: 'positive'
                    },
                    {
                        key: 'grateful',
                        mood: 'positive'
                    },
                    {
                        key: 'loved',
                        mood: 'positive'
                    },
                    {
                        key: 'respected',
                        mood: 'positive'
                    },
                    {
                        key: 'thoughtful',
                        mood: 'positive'
                    },
                    {
                        key: 'hopeful',
                        mood: 'positive'
                    },
                    {
                        key: 'nervous',
                        mood: 'negative'
                    },
                    {
                        key: 'anxious',
                        mood: 'negative'
                    },
                    {
                        key: 'worried',
                        mood: 'negative'
                    },
                    {
                        key: 'angry',
                        mood: 'negative'
                    },
                    {
                        key: 'tired',
                        mood: 'negative'
                    },
                    {
                        key: 'frustrated',
                        mood: 'negative'
                    },
                    {
                        key: 'bored',
                        mood: 'negative'
                    },
                    {
                        key: 'lonely',
                        mood: 'negative'
                    },
                    {
                        key: 'mischievous',
                        mood: 'positive'
                    },
                    {
                        key: 'strong',
                        mood: 'positive'
                    },
                    {
                        key: 'flat',
                        mood: 'negative'
                    },
                    {
                        key: 'upset',
                        mood: 'negative'
                    },
                    {
                        key: 'unwell',
                        mood: 'negative'
                    }
                ]
            }
        },
        {
            id: 'gad7',
            description: 'This is related to GAD7.',
            questionSet: [
                {
                    id: 'gad7q1',
                    description: 'Feeling nervous, anxious, or on edge',
                    questionType: {
                        maxSelection: 1,
                        selections: [
                            { key: 'notAtAll' },
                            { key: 'severalDays' },
                            { key: 'moreThanHalfTheDays' },
                            { key: 'nearlyEveryDay' }
                        ]
                    }
                },
                {
                    id: 'gad7q2',
                    description: 'Not being able to stop or control worrying',
                    questionType: {
                        maxSelection: 1,
                        selections: [
                            { key: 'notAtAll' },
                            { key: 'severalDays' },
                            { key: 'moreThanHalfTheDays' },
                            { key: 'nearlyEveryDay' }
                        ]
                    }
                },
                {
                    id: 'gad7q3',
                    description: 'Worrying too much about different things',
                    questionType: {
                        maxSelection: 1,
                        selections: [
                            { key: 'notAtAll' },
                            { key: 'severalDays' },
                            { key: 'moreThanHalfTheDays' },
                            { key: 'nearlyEveryDay' }
                        ]
                    }
                },
                {
                    id: 'gad7q4',
                    description: 'Trouble relaxing',
                    questionType: {
                        maxSelection: 1,
                        selections: [
                            { key: 'notAtAll' },
                            { key: 'severalDays' },
                            { key: 'moreThanHalfTheDays' },
                            { key: 'nearlyEveryDay' }
                        ]
                    }
                },
                {
                    id: 'gad7q5',
                    description: 'Being so restless that its hard to sit still',
                    questionType: {
                        maxSelection: 1,
                        selections: [
                            { key: 'notAtAll' },
                            { key: 'severalDays' },
                            { key: 'moreThanHalfTheDays' },
                            { key: 'nearlyEveryDay' }
                        ]
                    }
                },
                {
                    id: 'gad7q6',
                    description: 'Becoming easily annoyed or irritable',
                    questionType: {
                        maxSelection: 1,
                        selections: [
                            { key: 'notAtAll' },
                            { key: 'severalDays' },
                            { key: 'moreThanHalfTheDays' },
                            { key: 'nearlyEveryDay' }
                        ]
                    }
                },
                {
                    id: 'gad7q7',
                    description: 'Feeling afraid as if something awful might happen',
                    questionType: {
                        maxSelection: 1,
                        selections: [
                            { key: 'notAtAll' },
                            { key: 'severalDays' },
                            { key: 'moreThanHalfTheDays' },
                            { key: 'nearlyEveryDay' }
                        ]
                    }
                }
            ]
        },
        {
            id: 'describePositiveFeeling',
            description: 'One thing you are {feeling} for?',
            required: true,
            questionType: {
                type: 'freeText',
                maxLength: 2500,
                minLength: 25
            }
        },
        {
            id: 'describeNegativeFeeling',
            description: 'One thing you are {feeling} for?',
            required: true,
            questionType: {
                type: 'freeText',
                maxLength: 2500,
                minLength: 25
            }
        },
        {
            id: 'describeGenericFeeling',
            description: 'What’s the best thing that has happened to you in the last month?',
            required: true,
            questionType: {
                type: 'freeText',
                maxLength: 2500,
                minLength: 25
            }
        }
    ]
};
