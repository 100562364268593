export const HOME_PAGE = '/';
export const THANK_YOU_PAGE = '/question/submit';


export const SURVEY_OVERALL = '/question/overall';
export const SURVEY_ENERGY = '/question/energy';
export const SURVEY_SLEEP = '/question/sleep';
export const SURVEY_APPETITE = '/question/appetite';
export const SURVEY_FEELING = '/question/feeling';

export const SURVEY_GAD7 = '/question/gad7';
export const SURVEY_POSITIVE = '/question/positive';
export const SURVEY_NEGATIVE = '/question/negative';
export const SURVEY_GENERIC = '/question/generic';

export const APPOINTMENT = '/appointment';
export const APPOINTMENT_SUMMARY = '/appointment/summary';
export const APPOINTMENT_SUBMIT = '/appointment/submit';
