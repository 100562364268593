import { REQUEST_QUESTION, RECEIVE_QUESTION } from './consts';

export default function questionReducer (state = {}, action) {
    switch (action.type) {
    case RECEIVE_QUESTION:
        return {
            ...action.data,
            isFetched: true,
        };
    case REQUEST_QUESTION:
        return {
            ...state,
            isFetched: false,
        };
    default:
        return state;
    }
}
