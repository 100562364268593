import React from 'react';
import { hydrate } from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';

import App from 'App/App';
import configureStore from 'store/configureStore';
import 'assets/assets';

const { store } = configureStore(window.REDUX_STATE, createBrowserHistory());
const appElement = document.getElementById('app');

hydrate(
    <AppContainer>
        <Provider store={store}>
            <App />
        </Provider>
    </AppContainer>,
    appElement
);

if (module.hot) {
    module.hot.accept('App/App', () => {
        hydrate(
            <AppContainer>
                <Provider store={store}>
                    <App />
                </Provider>
            </AppContainer>,
            appElement
        );
    });
}
