import queryString from 'query-string';
import restoreScroll from 'redux-first-router-restore-scroll';
import { connectRoutes } from 'redux-first-router';

import getRoutesMap from './routesMap';

export default (history) =>
    connectRoutes(getRoutesMap(), {
        initialEntries: [history],
        querySerializer: queryString,
        restoreScroll: restoreScroll({
            shouldUpdateScroll: () => [0, 0]
        })
    });
