import React from 'react';
import PropTypes from 'prop-types';
import EmojiButton from './components/EmojiButton/EmojiButton';

import styles from './EmotionQuestion.scss';

const EmotionQuestion = ({
    input: { onChange },
    questionSet
}) => {
    return (
        <div className={styles.questionBox}>
            {
                questionSet.map((data, index) => {
                    return (
                        <div className={styles.buttonContainer} key={index}>
                            <EmojiButton value={data} onChange={onChange}/>
                        </div>
                    );
                })
            }
        </div>
    );
};

EmotionQuestion.propTypes = {
    input: PropTypes.object.isRequired,
    questionSet: PropTypes.array.isRequired,
};

export default EmotionQuestion;
