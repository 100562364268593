import React from 'react';
import PropTypes from 'prop-types';

import styles from './EmojiButton.scss';

const selectButton = (value, onChange) => {
    onChange(value);
};

const EmojiButton = ({ value, onChange }) => {
    const btnStyles = 'buttonValue'+value.voteCount;

    return (
        <div onClick={() => selectButton(value.key, onChange)} className={styles[btnStyles]}>
            <div className={styles.emoji}>{value.emoji}</div>
            <div className={styles.text}>{value.text}</div>
        </div>
    );
};

EmojiButton.propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func,
};

export default EmojiButton;
