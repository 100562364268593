import React from 'react';
import PropTypes from 'prop-types';

import styles from './ScaleBox.scss';

const ScaleBox = ({ number, point, colour }) => {
    return (
        <div style={{zIndex: number}} className={styles.container}>
            <div style={{background: colour}} className={ point ? styles.pointFocus : styles.point } />
        </div>
    );
};

ScaleBox.propTypes = {
    number: PropTypes.number,
    colour: PropTypes.string,
    point: PropTypes.bool
};

export default ScaleBox;
