import React, { useEffect, useState } from 'react';
import { push } from 'redux-first-router';
import Shell from '../../../components/Shell/Shell';
import ContainerBlock from '../../../components/ContainerBlock/ContainerBlock';
import Map from './components/Map/Map';
import SummaryHeader from './components/SummaryHeader/SummaryHeader';
import {
    APPOINTMENT,
    APPOINTMENT_SUBMIT,
} from '../../../../store/routes/routeNames';
import { getCookies } from '../../../../utils/cookies';

import styles from './SummaryPage.scss';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Button from '../../../components/Button/Button';
import Loading from '../../../components/Loading/Loading';
import Teleconference from './components/Teleconference/Teleconference';

const mapStateToProps = (state) => ({
    location: state.location,
});

const SummaryPage = ({ handleSubmit, location }) => {
    const trackingId =
        location && location.query ? location.query.tracking_id : '12345';
    const country = location && location.query ? location.query.country : 'uk';

    const [appointmentModeSet, setAppointmentModeSet] = useState('inPerson');
    const [isLoading, setIsLoading] = useState(false);
    const getAppointmentDate = getCookies('appointmentDate');
    useEffect(() => {
        if (!getAppointmentDate) {
            push(APPOINTMENT);
        }
    });

    const getAppointmentMode = (val) => {
        setAppointmentModeSet(val);
    };

    const submitForm = async () => {
        setIsLoading(true);

        setTimeout(() => push(APPOINTMENT_SUBMIT), 300);
    };

    const address =
        country === 'uk'
            ? '17 Waverley Road, Norwich, NR1 1BL'
            : '397 Smith St Fitzroy VIC 3065';

    return (
        <Shell>
            <ContainerBlock>
                <form onSubmit={handleSubmit(submitForm)}>
                    <SummaryHeader
                        trackingId={trackingId}
                        country={country}
                        data={getAppointmentDate}
                        onChange={(e) => getAppointmentMode(e)}
                    />
                    <div className={styles.summaryDetailWrapper}>
                        {appointmentModeSet === 'inPerson' ? (
                            <Map address={address} />
                        ) : (
                            <Teleconference />
                        )}
                    </div>
                    <div className={styles.btnWrapper}>
                        {isLoading ? (
                            <div className={styles.loadingWrapper}>
                                <Loading />
                            </div>
                        ) : (
                            <div className={styles.btnBox}>
                                <Button
                                    id='submit-schedule-button'
                                    text={'Confirm'}
                                />
                            </div>
                        )}
                    </div>
                </form>
            </ContainerBlock>
        </Shell>
    );
};

const ReduxForm = reduxForm({
    form: 'scheduleForm',
})(SummaryPage);

export default connect(mapStateToProps)(ReduxForm);
