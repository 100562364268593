import React from 'react';
import PropTypes from 'prop-types';
import mark from 'assets/images/arrow.png';

import styles from './QuestionHeader.scss';

const QuestionHeader = ({Question, Desc}) => {
    return (
        <div className={styles.container}>
            <div className={styles.icon}>
                <img className={styles.iconImg} src={mark}/>
            </div>
            <div className={styles.questionContainer}>
                <div className={styles.question}>{Question}</div>
                {Desc ? <div className={styles.instructionBox}>{Desc}</div> : null}
            </div>
        </div>
    );
};

QuestionHeader.propTypes = {
    Question: PropTypes.string.isRequired,
    Desc: PropTypes.string,
};


export default QuestionHeader;
