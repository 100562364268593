import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { push } from 'redux-first-router';

import Button from '../../../../components/Button/Button';
import AppetiteSliderQuestion from './components/AppetiteSliderQuestion/AppetiteSliderQuestion';

import styles from './AppetiteScaling.scss';
import {getCookies} from '../../../../../utils/cookies';

const AppetiteScaling = ({ data, profilePic, handleSubmit, nextPath, saveAnswer }) => {
    useEffect(() => {
        const getAnswer = getCookies('saveAnswer');
        setMyValue((getAnswer && getAnswer.scaleAppetite) ? getAnswer.scaleAppetite.answer.key : 20);
    });

    const [scalingList, setScaleList] = useState(data.questionSet);
    const [myValue, setMyValue] = useState(20);

    const getPosition = (myValue, currentValue) => {
        const value = parseInt(currentValue, 10);
        return myValue === value;
    };

    const selectScaleBar = (value) => {
        let newScalingList = [];
        for (let data of scalingList) {
            newScalingList.push({
                number: data.number,
                colour: data.colour,
                position: getPosition(data.number, value)
            });
        }
        setScaleList(newScalingList);
    };

    const submitForm = ({appetiteScalingLevel = myValue }) => {
        saveAnswer({
            [data.id]: {
                questionId: data.id,
                answer: { key: Math.floor(appetiteScalingLevel) }
            }
        });
        push(nextPath);
    };

    return (
        <form className={styles.content} onSubmit={handleSubmit(submitForm)}>
            <div className={styles.questionContainer}>
                <Field
                    name='appetiteScalingLevel'
                    component={AppetiteSliderQuestion}
                    defaultValue={myValue}
                    profilePic={profilePic}
                    questionSet={scalingList}
                    onChange={selectScaleBar}
                />
            </div>
            <div className={styles.buttonContainer}>
                <div className={styles.buttonBox}>
                    <Button id='submit-appetite-scaling-button' text={'Next'} />
                </div>
            </div>
        </form>
    );
};

AppetiteScaling.propTypes = {
    data: PropTypes.object.isRequired,
    profilePic: PropTypes.string.isRequired,
    nextPath: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func,
    saveAnswer: PropTypes.func.isRequired,
};

const ReduxForm = reduxForm({
    form: 'sliderQuestion',
})(AppetiteScaling);

export default connect()(ReduxForm);
