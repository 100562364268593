import i18n from 'i18next';
import resources from './resources';

i18n.init({
    lng: 'en',
    resources,
    fallbackLng: 'en',
    debug: false,

    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: false, // we use content as keys

    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ',',
    },

    react: {
        wait: true,
    },
});

export default i18n;
