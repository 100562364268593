import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { push } from 'redux-first-router';

import Button from '../../../../components/Button/Button';
import HourSliderQuestion from './components/HourSliderQuestion/HourSliderQuestion';

import styles from './SleepScaling.scss';
import {getCookies} from '../../../../../utils/cookies';

const SleepScaling = ({ data, profilePic, handleSubmit, nextPath, saveAnswer }) => {
    useEffect(() => {
        const getAnswer = getCookies('saveAnswer');
        setMyValue((getAnswer && getAnswer.scaleSleepQuality) ? getAnswer.scaleSleepQuality.answer.key : 20);
    });

    const [scalingList, setScaleList] = useState(data.questionSet);
    const [myValue, setMyValue] = useState(20);

    const getPosition = (myValue, currentValue) => {
        const value = parseInt(currentValue, 10);
        return myValue === value;
    };

    const selectScaleBar = (value) => {
        let newScalingList = [];
        for (let data of scalingList) {
            newScalingList.push({
                number: data.number,
                colour: data.colour,
                position: getPosition(data.number, value)
            });
        }
        setScaleList(newScalingList);
    };

    const submitForm = ({ energyScalingLevel = myValue }) => {
        saveAnswer({
            [data.id]: {
                questionId: data.id,
                answer: {
                    key: parseInt(energyScalingLevel, 10),
                    hourKey: data.questionSet.find(
                        selection => selection.number === parseInt(energyScalingLevel, 10)
                    ).key
                }
            }
        });
        push(nextPath);
    };

    return (
        <form className={styles.content} onSubmit={handleSubmit(submitForm)}>
            <div className={styles.questionContainer}>
                <Field
                    name='energyScalingLevel'
                    component={HourSliderQuestion}
                    defaultValue={myValue}
                    profilePic={profilePic}
                    questionSet={scalingList}
                    onChange={selectScaleBar}
                />
            </div>
            <div className={styles.buttonContainer}>
                <div className={styles.buttonBox}>
                    <Button id='submit-sleep-scaling-button' text={'Next'} />
                </div>
            </div>
        </form>
    );
};

SleepScaling.propTypes = {
    data: PropTypes.object.isRequired,
    profilePic: PropTypes.string.isRequired,
    nextPath: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func,
    saveAnswer: PropTypes.func.isRequired,
};

const ReduxForm = reduxForm({
    form: 'sliderQuestion',
})(SleepScaling);

export default connect()(ReduxForm);
