import React from 'react';
import PropTypes from 'prop-types';

import styles from './ScaleBox.scss';

const ScaleBox = ({ point, colour }) => {
    return (
        <div className={styles.container}>
            {point}
            <div style={{background: colour}} className={ point ? styles.pointFocus : styles.point } />
        </div>
    );
};

ScaleBox.propTypes = {
    colour: PropTypes.string,
    point: PropTypes.bool
};

export default ScaleBox;
