import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Shell from '../../../components/Shell/Shell';
import ContainerBlock from '../../../components/ContainerBlock/ContainerBlock';
import AppointmentHeader from './components/AppointmentHeader/AppointmentHeader';
import Calender from './components/calender/calender';

import styles from './AppointCalender.scss';

const mapStateToProps = (state) => ({
    scheduleList: state.scheduleList,
    location: state.location
});

const AppointCalender = ({scheduleList: { data }, location}) => {
    const trackingId = location.query.tracking_id;
    const country = location.query.country;

    return (
        <Shell>
            <ContainerBlock>
                <AppointmentHeader name={data.drName} desc={data.desc} profilePic={data.profilePic} />
                <div className={styles.appointmentWrapper}>
                    <div className={styles.title}>Find and select the best time for you</div>
                </div>
                <div className={styles.filterContainer}>
                    <div className={styles.filterWrapper}>
                        <div className={styles.filterDate}>This week</div>
                        <div className={styles.filterDateActive}>Next week</div>
                        <div className={styles.filterDate}>2 weeks out</div>
                        <div className={styles.filterDate}>3 weeks out</div>
                    </div>
                </div>
                <div>
                    {
                        data.scheduleList.map((data, index) => {
                            return (
                                <Calender trackingId={trackingId} data={data} country={country} key={index} />
                            );
                        })
                    }
                </div>
            </ContainerBlock>
        </Shell>
    );
};

AppointCalender.propTypes = {
    handleSubmit: PropTypes.func,
    pristine: PropTypes.bool
};

export default connect(mapStateToProps)(AppointCalender);
