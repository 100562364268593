import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import styles from './Button.scss';

const Button = ({id, text, isSecondary, disabled}) => {
    return disabled ? (
        <Fragment>
            <button id={id} className={styles.disableButton} disabled={disabled}>{text}</button>
        </Fragment>
    ) : (
        <Fragment>
            <button id={id} className={isSecondary ? styles.secondaryButton : styles.button} disabled={disabled}>{text}</button>
        </Fragment>
    );
};

Button.propTypes = {
    id: PropTypes.string,
    text: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    isSecondary: PropTypes.bool,
};

export default Button;
