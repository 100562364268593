import React from 'react';
import PropTypes from 'prop-types';

import styles from './InputField.scss';

const InputField = ({placeholder, maxValue, input: { onChange }, value}) => {
    return (
        <div className={styles.container}>
            <input
                maxLength={maxValue}
                className={styles.field}
                placeholder={placeholder}
                onChange={onChange}
                value={value}
            />
        </div>
    );
};

InputField.propTypes = {
    input: PropTypes.object.isRequired,
    maxValue: PropTypes.number,
    placeholder: PropTypes.string,
    value: PropTypes.string
};

export default InputField;
